import type { TSeo } from "@/types/graphql/seo"
import parse from "html-react-parser"
import Head from "next/head"

export function Seo(seo: TSeo) {
  return (
    <Head>
      <title>{seo.title}</title>
      {parse(seo.fullHead)}
    </Head>
  )
}
