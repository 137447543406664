import { Breadcrumbs } from "@/components/breadcrumbs"
import { ThemeProvider } from "@/components/context/theme"
import { Footer } from "@/components/footer"
import Header from "@/components/header"
import { LoginForm } from "@/components/loginForm"
import { PageComponents } from "@/components/pageComponents"
import { Seo } from "@/components/seo"
import { client } from "@/lib/graphql/client"
import {
  getSiteSettingsFragment,
  getThemeSettingsFragment
} from "@/lib/graphql/fragments"
import {
  getMenuItems,
  getPathDataByPath,
  redirectQuery
} from "@/lib/graphql/queries"
import { logWPGrahpqlErrorsBetter } from "@/lib/wp-graphql"
import { TMenuLocations } from "@/types/graphql/menus"
import type { TPageComponents } from "@/types/graphql/pageComponents"
import type { TObjectData, TPathData } from "@/types/graphql/pathData"
import { gql } from "@apollo/client"
import type {
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType
} from "next"
import Script from "next/script"
import React from "react"

export type TData = TPathData &
  TObjectData & {
    object: null | {
      pageComponentsGroup: TPageComponents
    }
  }

function Path(props: InferGetStaticPropsType<typeof getStaticProps>) {
  let {
    primaryMenuItems,
    footerMenuItems,
    secondaryMenuItems,
    object,
    themeSettingsPage,
    seo
  } = props

  let initialState = {
    secondaryMenuItems,
    primaryMenuItems,
    footerMenuItems,
    themeSettingsPage,
    pageComponents: object?.pageComponentsGroup?.pageComponents
  }

  if (object.isRestricted) {
    return (
      <ThemeProvider initialState={initialState}>
        <Header />
        <main id="main">
          <LoginForm />
        </main>
        <Footer />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider initialState={initialState} databaseId={object.databaseId}>
      <Script id="databaseId">{`window.postId = ${object.databaseId}`}</Script>
      <Seo {...object.seo} />
      <Header />
      <Breadcrumbs
        breadcrumbs={object.seo.breadcrumbs}
        separator={seo.breadcrumbs.separator}
        isFrontPage={object.isFrontPage}
      />
      <main id="main" className="flex-grow">
        <PageComponents components={object.pageComponentsGroup} />
      </main>
      <Footer />
    </ThemeProvider>
  )
}

export const getStaticProps: GetStaticProps<TData> = async ({ params }) => {
  let slugs = params?.path as string[]

  if (slugs && slugs[0] === "index") {
    slugs = []
  }

  let path = slugs?.length ? "/" + slugs.join("/") + "/" : "/"
  let query = `#graphql
    query {
      ${getPathDataByPath(path)}
      ${getMenuItems(TMenuLocations.PRIMARY)}
      ${getMenuItems(TMenuLocations.FOOTER)}
      ${getMenuItems(TMenuLocations.SECONDARY)}
      ${redirectQuery(path)}
      ${getThemeSettingsFragment()}
      ${getSiteSettingsFragment()}
    }
  `
  try {
    let { data } = await client.query<TData>({
      query: gql(query)
    })

    if (data.redirect) {
      return {
        redirect: {
          destination: data.redirect.target,
          permanent: [301, 308].includes(data.redirect.status)
        }
      }
    }

    if (!data.object) {
      return {
        notFound: true
      }
    }

    if (data.object.isRestricted && data.object.pageComponentsGroup) {
      data.object.pageComponentsGroup.pageComponents = []
    }

    return {
      props: data,
      revalidate: 1
    }
  } catch (error) {
    logWPGrahpqlErrorsBetter(error)

    return {
      notFound: true
    }
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: "blocking"
  }
}

export default Path
