import Link from "next/link"
import { Fragment } from "react"
import parse from "html-react-parser"
import { useThemeData } from "./context/theme"
import cx from "classnames"
import type { THero } from "./pageComponents/hero"

type Props = {
  breadcrumbs?: {
    text: string
    url: string
  }[]
  separator: string
  isFrontPage?: boolean
}

export function Breadcrumbs({ breadcrumbs, separator, isFrontPage }: Props) {
  const { pageComponents } = useThemeData()

  if (isFrontPage) {
    return null
  }

  if (!breadcrumbs) {
    return null
  }

  const firstHero = pageComponents?.find(
    (item, index): item is THero =>
      index === 0 &&
      item.fieldGroupName.includes("Pagecomponentsgroup_PageComponents_Hero")
  )

  const classNames = cx(
    "p-4 text-sm",
    firstHero ? "text-white/70" : "text-gray-600/70",
    {
      "absolute top-header-offset z-20": firstHero,
      "inset-x-0 bg-gradient-to-b from-black/60 to-transparent lg:max-w-[50%] lg:bg-none pb-24":
        firstHero && firstHero?.startpage && firstHero?.image
    }
  )

  const linkClassNames = cx(
    "last:pointer-events-none",
    firstHero ? "last:text-white" : "last:text-gray-600"
  )

  const sepClassNames = cx(
    "mx-1.5 pointer-events-none",
    firstHero ? "last:text-white" : "last:text-gray-600"
  )

  return (
    <div className={classNames}>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Fragment key={index}>
            <Link key={index} href={breadcrumb.url}>
              <a className={linkClassNames}>{parse(breadcrumb.text)}</a>
            </Link>
            {index < breadcrumbs.length - 1 && (
              <span className={sepClassNames}>{separator}</span>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
