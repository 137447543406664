import { useForm } from "react-hook-form"

type FormValues = {
  password: string
}

export function LoginForm() {
  let { register, handleSubmit } = useForm<FormValues>()

  const onSubmit = handleSubmit(async ({ password }) => {
    fetch(`/api/password?password=${password}&url=${window.location.pathname}`)
  })

  return (
    <div className="container mx-auto">
      <form onSubmit={onSubmit}>
        <input type="password" {...register("password", { required: true })} />
        <button type="submit">Send</button>
      </form>
    </div>
  )
}
