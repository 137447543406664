import useAuth from "@/lib/hooks/useAuth"
import type {
  TComponentKey,
  TPageComponents
} from "@/types/graphql/pageComponents"
import type { TCookieDeclaration } from "components/pageComponents/cookieDeclaration"
import dynamic from "next/dynamic"
import type { ElementType } from "react"

let componentMapping: Record<TComponentKey, ElementType> = {
  Page_Pagecomponentsgroup_PageComponents_Hero: dynamic(
    () => import("@/pageComponents/hero")
  ),
  Page_Pagecomponentsgroup_PageComponents_Columns: dynamic(
    () => import("@/pageComponents/columns")
  ),
  Page_Pagecomponentsgroup_PageComponents_Archive: dynamic(
    () => import("@/pageComponents/archive")
  ),
  Page_Pagecomponentsgroup_PageComponents_CookieDeclaration:
    dynamic<TCookieDeclaration>(
      () => import("@/pageComponents/cookieDeclaration")
    ),
  Page_Pagecomponentsgroup_PageComponents_TextBlock: dynamic(
    () => import("@/pageComponents/textBlock")
  ),
  Page_Pagecomponentsgroup_PageComponents_Accordion: dynamic(
    () => import("@/pageComponents/accordion")
  ),
  Page_Pagecomponentsgroup_PageComponents_List: dynamic(
    () => import("@/pageComponents/list")
  ),
  Page_Pagecomponentsgroup_PageComponents_ImageAndText: dynamic(
    () => import("@/pageComponents/imageAndText")
  ),
  Page_Pagecomponentsgroup_PageComponents_NewsCards: dynamic(
    () => import("@/pageComponents/newsCards")
  ),
  Page_Pagecomponentsgroup_PageComponents_Document: dynamic(
    () => import("@/pageComponents/documents")
  ),
  Page_Pagecomponentsgroup_PageComponents_Rates: dynamic(
    () => import("@/pageComponents/rates")
  )
}

type Props = {
  components: TPageComponents
}

export function PageComponents({ components }: Props) {
  const { loggedIn, loading } = useAuth()
  if (!components) {
    return null
  }

  return (
    <>
      {components.pageComponents.map((component, index) => {
        let firstUnderline = component.fieldGroupName.indexOf("_")
        let fieldGroupName = component.fieldGroupName.substr(firstUnderline + 1)

        const { visibility } = component

        if (visibility !== "1" && loading) {
          return null
        }

        if (
          (visibility === "2" && loggedIn) ||
          (visibility === "3" && !loggedIn) ||
          (visibility === "3" && loading)
        ) {
          return null
        }

        fieldGroupName = "Page_" + fieldGroupName

        let Component =
          componentMapping[fieldGroupName as keyof typeof componentMapping]

        if (!Component) {
          return (
            <div key={index}>
              <h2>Component not found!</h2>
              {JSON.stringify(component)}
            </div>
          )
        }

        return <Component key={index} {...component} />
      })}
    </>
  )
}
